* {
  box-sizing: border-box;
}

body, html {
  margin: 0;
  padding: 0;
  font: 10pt/1.2 'Source Sans Pro', sans-serif;
  background: #f8f9fa;
}

p {
  margin: 0.375cm 0;
}

@page {
  size: A4 portrait;
  margin: 0;
}

@media print {
  body, html {
    width: 210mm;
    background: #fff;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}
