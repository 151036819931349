.l9JSuG_section {
  margin: 0 0 .25cm;
}

.l9JSuG_section label {
  display: block;
}

.l9JSuG_section input {
  margin: 0 .5em;
}

.vDpQ3G_container {
  position: fixed;
  bottom: .25cm;
  right: .25cm;
}

.vDpQ3G_container button {
  font: inherit;
  color: #666;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  margin: 0 .25cm;
  padding: .25cm;
}

.vDpQ3G_container button svg path {
  stroke: currentColor;
  fill: currentColor;
}

.vDpQ3G_container button:hover, .vDpQ3G_container button.vDpQ3G_active {
  color: #333;
}

.vDpQ3G_container .vDpQ3G_menu {
  min-width: 15em;
  background: #fff;
  padding: .25cm .5cm;
  position: absolute;
  bottom: 100%;
  right: 0;
  box-shadow: 0 5px 10px #aaa;
}

.vDpQ3G_container .vDpQ3G_menu h3 {
  margin-top: .25cm;
}

.vDpQ3G_container .vDpQ3G_menu h4 {
  margin: .5cm 0 .25cm;
}

@media print {
  .vDpQ3G_container {
    display: none;
  }
}

.L6PeXq_container {
  text-align: center;
  margin: 0 0 10px;
}

.L6PeXq_container.L6PeXq_print-only {
  display: none;
}

.L6PeXq_title {
  margin: 0;
  font: 900 26pt / 1.15 Source Sans Pro, sans-serif;
}

.L6PeXq_subtitle {
  color: #666;
  margin: 0;
  font: 300 15pt / 1.15 Source Sans Pro, sans-serif;
}

.L6PeXq_section {
  color: #333;
  margin: .35cm 0 .375cm;
  font: 900 14pt / 1.15 Source Sans Pro, sans-serif;
}

@media print {
  .L6PeXq_container.L6PeXq_print-only {
    display: block;
  }
}

.RMqSkW_container {
  position: relative;
  overflow: hidden;
}

.RMqSkW_container .RMqSkW_content {
  overflow-x: auto;
}

.RMqSkW_container .RMqSkW_content > :first-child {
  float: left;
  width: 100%;
}

.RMqSkW_container .RMqSkW_content:after {
  height: 0;
  clear: left;
  content: "";
  display: block;
}

.RMqSkW_container:before, .RMqSkW_container:after {
  width: 12px;
  height: 100%;
  z-index: 2;
  content: "";
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transition: -webkit-transform .15s ease-in, -moz-transform .15s ease-in, transform .15s ease-in;
  -moz-transition: -webkit-transform .15s ease-in, -moz-transform .15s ease-in, transform .15s ease-in;
  transition: -webkit-transform .15s ease-in, -moz-transform .15s ease-in, transform .15s ease-in;
  display: block;
  position: absolute;
  top: 0;
}

.RMqSkW_container:before {
  background: -webkit-linear-gradient(90deg, rgba(0, 0, 0, .4) 0%, rgba(255, 255, 255, 0) 100%);
  background: -moz-linear-gradient(90deg, rgba(0, 0, 0, .4) 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(90deg, rgba(0, 0, 0, .4) 0%, rgba(255, 255, 255, 0) 100%);
  left: 0;
  -webkit-transform: translateX(-12px);
  -moz-transform: translateX(-12px);
  transform: translateX(-12px);
}

.RMqSkW_container:after {
  background: -webkit-linear-gradient(270deg, rgba(0, 0, 0, .4) 0%, rgba(255, 255, 255, 0) 100%);
  background: -moz-linear-gradient(270deg, rgba(0, 0, 0, .4) 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(270deg, rgba(0, 0, 0, .4) 0%, rgba(255, 255, 255, 0) 100%);
  right: 0;
  -webkit-transform: translateX(12px);
  -moz-transform: translateX(12px);
  transform: translateX(12px);
}

.RMqSkW_container.RMqSkW_shadow-left:before, .RMqSkW_container.RMqSkW_shadow-right:after {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0);
}

@media screen and (max-width: 640px) {
  .RMqSkW_container.RMqSkW_wide {
    margin-left: -.5cm;
    margin-right: -.5cm;
  }
}

@media print {
  .RMqSkW_container:before, .RMqSkW_container:after {
    display: none;
  }
}

.vPfgpq_container {
  min-width: 299px;
  border-collapse: collapse;
  border: none;
  margin: 0 0 .25cm;
  font-size: 9pt;
}

.vPfgpq_container th {
  text-align: right;
}

.vPfgpq_container th, .vPfgpq_container td {
  border: 1px solid #666;
  padding: 5px;
}

.vPfgpq_container .vPfgpq_single .vPfgpq_idx {
  width: 8%;
}

.vPfgpq_container .vPfgpq_single .vPfgpq_name {
  width: 34%;
}

.vPfgpq_container .vPfgpq_single .vPfgpq_note {
  width: 58%;
}

.vPfgpq_container .vPfgpq_double .vPfgpq_idx {
  width: 4%;
}

.vPfgpq_container .vPfgpq_double .vPfgpq_name {
  width: 18%;
}

.vPfgpq_container .vPfgpq_double .vPfgpq_note {
  width: 28%;
}

.L4Frza_help {
  color: #999;
}

@media print {
  .L4Frza_help {
    color: #333;
  }

  .L4Frza_screen-only {
    display: none;
  }
}

.lBC_fa_list {
  margin-bottom: 2em;
}

.lBC_fa_list > li + li {
  margin-top: .25em;
}

.h3t5da_container {
  background: #fff;
}

@media screen {
  .h3t5da_container {
    max-width: 21cm;
    margin: 1cm auto;
    padding: 1cm;
    box-shadow: 0 2px 10px #ddd;
  }
}

@media screen and (max-width: 640px) {
  .h3t5da_container {
    padding: 1cm .5cm;
  }
}

@media print {
  .h3t5da_container {
    page-break-after: always;
    break-after: page;
    page-break-inside: avoid;
    break-inside: avoid;
    padding: 1cm;
  }

  .h3t5da_container.h3t5da_screenOnly {
    display: none;
  }
}

.uJzbWW_container {
  min-width: 15cm;
  margin: 1cm 0 .5cm;
  position: relative;
}

.uJzbWW_container:before {
  width: 0;
  height: 0;
  content: "";
  padding-bottom: 45%;
  display: block;
}

.uJzbWW_footer {
  text-align: center;
  color: #666;
  margin-top: .5cm;
  margin-bottom: 3em;
  font: 300 13pt / 1.15 Source Sans Pro, sans-serif;
}

.uJzbWW_debugDot {
  z-index: 10000;
  -webkit-transform-origin: center;
  -moz-transform-origin: center;
  transform-origin: center;
  width: 9px;
  height: 9px;
  background: red;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

._0MgADa_container {
  -webkit-transform-origin: center;
  -moz-transform-origin: center;
  transform-origin: center;
  white-space: nowrap;
  background: #d2e2f1;
  border: 1px solid #000;
  border-radius: 10px;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-flex-flow: column;
  flex-flow: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 15px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

._0MgADa_name {
  font-size: 10pt;
  font-weight: 900;
}

._0MgADa_instruments {
  font-size: 16pt;
}

._0MgADa_details {
  margin-top: 2px;
  font-size: 10pt;
  font-weight: 300;
}

._0MgADa_addon {
  background: #d2e2f1;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 10px 15px;
  font-size: 10pt;
  position: absolute;
}

._0MgADa_mic {
  width: 10px;
  height: 21px;
  -webkit-transform-origin: center;
  -moz-transform-origin: center;
  transform-origin: center;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translate(-50%, -60%);
  -moz-transform: translate(-50%, -60%);
  transform: translate(-50%, -60%);
}

._0MgADa_monitor {
  width: 80px;
  height: 45px;
  -webkit-transform-origin: center;
  -moz-transform-origin: center;
  transform-origin: center;
  position: absolute;
  top: 50%;
  left: 50%;
}

* {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body, html {
  background: #f8f9fa;
  margin: 0;
  padding: 0;
  font: 10pt / 1.2 Source Sans Pro, sans-serif;
}

p {
  margin: .375cm 0;
}

@page {
  size: A4 portrait;
  margin: 0;
}

@media print {
  body, html {
    width: 210mm;
    background: #fff;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}

/*# sourceMappingURL=index.77f02311.css.map */
